<template>
  <div>
    <component
      :is="AdvertiseTee"
    />
    <div class="header-inside">
      <div class="header-left">
        <div class="header-logo">
          <Logo
            :width="`23`"
            :longwidth="`130`"
          />
        </div>
      </div>
      <div class="header-right">
        <button
          color="3xl"
          fsize="lg"
          :title="$t('menu')"
          area-label="Open Side Panel"
          @click="$globalFunctions.pageLayout('panel', !layout.panel)"
        >
          <v-icon :icon="layout.panel ? `mdi-chevron-left` : `mdi-menu`" variant="plain" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '~/store/global'
// import AdvertiseTee from '~/component/advertise/tee.vue'

export default {
  setup () {

    // 글로벌 스토어
    const { layout } = storeToRefs(useGlobalStore())

    // 메모리 비우기
    function restore () {
      AdvertiseTee.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const AdvertiseTee: any = shallowRef(null)
    const loadComponent = async () => {
      const AdvertiseTee_load = await import('~/component/advertise/tee.vue')
      AdvertiseTee.value = AdvertiseTee_load.default || AdvertiseTee_load
    }
    loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      layout,

      // 컴포넌트
      AdvertiseTee
    }
  }
}
</script>
